import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Uris } from './Uris';
import { Observable, map } from 'rxjs';
import {
  NewUser,
  User,
  new_user_code,
  reference,
} from '../../core/interfaces/interfaces.interfaces';
import { CACHING_ENABLED } from '../../core/interceptors/headers.handler.interceptor';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  public user: User | null = null;
  constructor(public http: HttpClient) {}

  createUserRefed(body: new_user_code): Observable<new_user_code | null> {
    return this.http
      .post<new_user_code>(Uris.REGISTER, body, {
        context: new HttpContext().set(CACHING_ENABLED, false),
      })
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }
  createUserRefedFreeAdmin(
    body: new_user_code,
  ): Observable<new_user_code | null> {
    return this.http.post<new_user_code>(Uris.REGISTER_ADMIN, body).pipe(
      map((resp) => {
        return resp;
      }),
    );
  }
  createUser(body: NewUser): Observable<User> {
    return this.http.post<User>(Uris.USERS, body).pipe(
      map((resp) => {
        return resp;
      }),
    );
  }
  getUsers(query: string): Observable<{ count: number; results: User[] }> {
    return this.http
      .get<{ count: number; results: User[] }>(Uris.USERS + query)
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }
  getCodeUser(): Observable<reference> {
    return this.http.get<reference>(Uris.REFERENCE).pipe(
      map((resp) => {
        return resp;
      }),
    );
  }
  generateCodeUser(): Observable<reference> {
    return this.http.post<reference>(Uris.REFERENCE, {}).pipe(
      map((resp) => {
        return resp;
      }),
    );
  }
  editUserClient(body: simple_client): Observable<simple_client> {
    return this.http.patch<simple_client>(Uris.USER_CLIENT, body).pipe(
      map((resp) => {
        return resp;
      }),
    );
  }
  get_user_by_id(id: number) {
    return this.http.get<User>(`${Uris.USERS}${id}/`).pipe(
      map((resp) => {
        return resp;
      }),
    );
  }
  get_user(): Observable<User> {
    return this.http.get<User>(`${Uris.USERS}refresh/`).pipe(
      map((resp) => {
        localStorage.setItem('USER', JSON.stringify(resp));
        return { ...resp };
      }),
    );
  }
}
export interface simple_client {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
}
