import { HttpClient, HttpContext } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Uris } from './Uris';
import { Observable, map } from 'rxjs';
import { PaymentMethod } from '@stripe/stripe-js';
import { CACHING_ENABLED } from '../../core/interceptors/headers.handler.interceptor';
import { invoice } from '../../core/interfaces/interfaces.interfaces';
import { suscription } from '../pages/register/const';
import { CACHING_ALERT_ERROR } from '../../core/interceptors/error.handler.interceptor';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(public http: HttpClient) {}

  get_payment_client_secret(): Observable<{ customer_secret: string }> {
    return this.http
      .get<{ customer_secret: string }>(`${Uris.PAYMENT}customer_secret/`)
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }
  get_payments_methods(): Observable<Array<PaymentMethod>> {
    return this.http
      .get<Array<PaymentMethod>>(`${Uris.PAYMENT}my_payment_methods/`)
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }

  delete_payments(id: string): Observable<any> {
    return this.http
      .delete<any>(`${Uris.PAYMENT}my_payment_methods/${id}/`)
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }
  set_as_default(id: string, card: PaymentMethod): Observable<any> {
    return this.http
      .post<any>(
        `${Uris.PAYMENT}my_payment_methods/${id}/set_as_default/`,
        card,
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }
  get_tier_data(): Observable<tier[]> {
    return this.http
      .get<tier[]>(`${Uris.PAYMENT}tiers_data/`, {
        context: new HttpContext().set(CACHING_ENABLED, false),
      })
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }
  get_my_subscription(): Observable<tier[]> {
    return this.http.get<tier[]>(`${Uris.PAYMENT}my_subscriptions/`).pipe(
      map((resp) => {
        return resp;
      }),
    );
  }

  create_subscription(body: { tier: number }): Observable<suscription> {
    return this.http
      .post<suscription>(`${Uris.PAYMENT}my_subscriptions/`, body)
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }

  cancel_subscription(): Observable<any> {
    return this.http
      .post<any>(`${Uris.PAYMENT}my_subscriptions/cancel/`, {})
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }
  change_subscription(body: { tier: number }): Observable<{ tier: number }> {
    return this.http
      .post<{ tier: number }>(`${Uris.PAYMENT}my_subscriptions/change/`, body)
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }
  get_stripe_payment_method(body: {
    stripe_id: string;
  }): Observable<PaymentMethod> {
    return this.http
      .post<PaymentMethod>(
        `${Uris.PAYMENT}my_payment_methods/get_stripe_payment_method/`,
        body,
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }
  get_invoices(
    query: string,
  ): Observable<{ count: number; results: invoice[] }> {
    return this.http
      .get<{
        count: number;
        results: invoice[];
      }>(`${Uris.PAYMENT}my_invoices/${query}`)
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }
  generate_account(body: { url: string }): Observable<account_link> {
    return this.http.post<account_link>(`${Uris.USERS}accounts/`, body).pipe(
      map((resp) => {
        return resp;
      }),
    );
  }
  force_retrieve_user_subscriptions(
    user: number,
    detail: { detail: string },
  ): Observable<{ detail: string }> {
    return this.http
      .post<{
        detail: string;
      }>(
        `${Uris.PAYMENT}admin/force_retrieve_user_subscriptions/${user}/`,
        detail,
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }
  pay_user(body: pay_user): Observable<pay_user> {
    return this.http.post<pay_user>(`${Uris.PAYMENT}admin/payout/`, body).pipe(
      map((resp) => {
        return resp;
      }),
    );
  }
  calculate_payment(body: {}, user: number): Observable<calculate_payment> {
    return this.http
      .post<calculate_payment>(
        `${Uris.PAYMENT}paypal/payout/calculate_payment/${user}/`,
        body,
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }
  paypal_payout(body: {
    amount: number;
    user: number;
  }): Observable<paypal_payout> {
    return this.http
      .post<paypal_payout>(`${Uris.PAYMENT}paypal/payout/`, body)
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }

  list_paypal_payout(
    query: string,
  ): Observable<{ count: number; results: paypal_payout[] }> {
    return this.http
      .get<{
        count: number;
        results: paypal_payout[];
      }>(`${Uris.PAYMENT}paypal/payout/${query}`)
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }
}
interface pay_user {
  user_id: number;
  amount: number;
}
export interface tier {
  name: string;
  price: number;
  price_with_decimal: number;
  tier?: 0 | 1 | 2 | undefined;
  status:
    | 'active'
    | 'past_due'
    | 'canceled'
    | 'unpaid'
    | 'paused'
    | 'incomplete'
    | 'incomplete_expired';
}

interface account_link {
  account_id: string;
  created: string;
  expires_at: string;
  url: string;
}
export interface calculate_payment {
  total: number;
  total_decimal: number;
  dt_start: string;
  dt_end: string;
}

export interface paypal_payout {
  customer: number;
  amount: number;
  tracking_number: string;
  status: 'pending' | 'paid' | 'canceled' | 'failed';
  arrival_at: string | null;
  created_at: string;
  paypal_transaction_id?: null | string;
  paypal_created_at?: null | string;
  id?: string;
}
