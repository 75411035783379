import { EventEmitter, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { PaymentService, tier } from './payment.service';
import { Router } from '@angular/router';
import { UsersService } from './users.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalFunctionService {
  my_subscription = new BehaviorSubject(false);
  current_subscription = this.my_subscription.asObservable();

  constructor(
    private toastrService: ToastrService,
    public paymentService: PaymentService,
    public router: Router,
    public usersService: UsersService,
  ) {}
  updateSuscription(status: boolean) {
    this.my_subscription.next(status);
  }

  alertAnimation(
    alert: 'error' | 'warning' | 'success',
    message?: string | undefined,
    title?: string | undefined,
    config: any = {},
  ) {
    this.toastrService[alert](message, title, config);
  }
  validate_color(plan: 0 | 1 | 2 | '') {
    if (plan === 0) return '#e3e4e5';
    if (plan === 1) return '#ffbf00';
    if (plan === 2) return '#7f7679';
    return '#999999';
  }

  copyText(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  formatPhone(text: string) {
    // Eliminar cualquier carácter que no sea un dígito
    let numeroLimpio = text.replace(/\D/g, '');

    // Aplicar el formato deseado
    let numeroFormateado = numeroLimpio.replace(
      /(\d{2})(\d{4})(\d{2})(\d{2})/,
      '+$1 $2 $3 $4',
    );

    // Actualizar el valor del input con el número formateado
    return numeroFormateado;
  }
  optionSwalAlert = async (title: string, text: string) => {
    let bool = false;

    const { value } = await Swal.fire({
      title,
      text,
      showCancelButton: true,
      confirmButtonText: `Aceptar`,
      cancelButtonText: `No, cancelar`,
      iconColor: '',
      customClass: {
        confirmButton: 'btn btn-primary bg-primary',
        cancelButton: 'btn btn-danger bg-danger',
      },
    });

    if (value) {
      bool = true;
    } else {
      Swal.close();
    }
    return bool;
  };
  validate_status = (suscriptions: tier[]) => {
    if (suscriptions) {
      const validate = suscriptions.some(
        (item) => item.status === 'active' || item.status === 'incomplete',
      );
      return validate;
    }
    return false;
  };
  validate_repeat(list: any[]) {
    let idSet = new Set();
    let listaSinRepetidos = [];
    for (let item of list) {
      if (!idSet.has(item.id)) {
        listaSinRepetidos.push(item);
        idSet.add(item.id);
      }
    }
    return listaSinRepetidos;
  }

  generate_account = () => {
    this.paymentService
      .generate_account({ url: window.location.origin + '/' })
      .subscribe((resp) => {
        window.open(resp.url, '_blank');
      });
  };
}
